import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
// import LinkButton from "../components/LinkButton";
// import LogoCloud from "../components/LogoCloud";
import StimulusReflexSvg from "../images/logos/stimulus_reflex.svg";
import LinkButton from "../components/LinkButton";

const StimulusReflexPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="First Hand StimulusReflex Consulting" />
          <Card
            visible={cardVisible}
            header="First Hand StimulusReflex Consulting"
          >
            <figure className="w-full flex justify-center">
              <img alt="StimulusReflex logo" src={StimulusReflexSvg} />
            </figure>
            <p className="text-base leading-6 text-gray-700 text-left mb-4">
              In an engineer’s work life, there are periods of creative
              expansion and consolidation. In late 2019, after having road
              tested several back- and frontend frameworks, I stumbled upon a
              relatively young project that promised to simplify Rails frontend
              development by reviving the HTML-over-the-wire approach using
              Websockets.
            </p>

            <p className="text-base leading-6 text-gray-700 text-left mb-4">
              Fast forward one year, and I’m on the{" "}
              <a
                className="text-magenta-900"
                target="_blank"
                href="https://docs.stimulusreflex.com"
                rel="noopener noreferrer"
              >
                StimulusReflex
              </a>{" "}
              core team. Needless to say in these times, and probably a faint
              anecdote in a few years, the Corona pandemic had the paradoxical
              effect - by requiring social distancing in the immediate community
              - of bringing people on opposite ends of the world closer
              together.
            </p>
            <p className="text-base leading-6 text-gray-700 text-left mb-4">
              It so happened that while I was increasingly working alone and in
              need of a comprehensive and productive full stack solution, I
              started contributing to and polishing StimulusReflex and{" "}
              <a
                className="text-magenta-900"
                target="_blank"
                href="https://cableready.stimulusreflex.com"
                rel="noopener noreferrer"
              >
                CableReady
              </a>{" "}
              together with{" "}
              <a
                className="text-magenta-900"
                target="_blank"
                href="https://discord.gg/stimulus-reflex"
                rel="noopener noreferrer"
              >
                a bunch of fellow enthusiasts
              </a>
              .
            </p>

            <h2 className="text-xl text-left leading-6 font-medium text-gray-900 border-b my-2 py-2">
              How can I help you?
            </h2>
            <p className="text-base leading-6 text-gray-700 text-left mb-4">
              I have set up a tailored process for reviewing and auditing Rails
              applications that are built with StimulusReflex. Typically, this
              involves
              <ul className="list-disc list-outside mt-2 mx-auto w-3/4">
                <li>
                  an <strong>Infrastructure Review</strong>, ensuring all your
                  services ensure optimal performance and security for
                  Websockets
                </li>
                <li>
                  an <strong>App Architecture Review</strong> to assure
                  extensibility in the future
                </li>
                <li>
                  a <strong>Code Style and Quality Review</strong> to suggest
                  potential refactorings around common code smells
                </li>
              </ul>
            </p>
            <p className="text-base leading-6 text-gray-700 text-left mb-4">
              plus, optionally:
              <ul className="list-disc list-outside mt-2 mx-auto w-3/4">
                <li>
                  a <strong>Test and Coverage Review</strong>, especially
                  focusing on adequate (system) testing of reflexes
                </li>
                <li>
                  a <strong>Performance Review</strong>, to deliver optimal
                  back- and frontend performance for a delightful user
                  experience
                </li>
                <li>
                  a <strong>Security Review</strong> doubling down on WebSocket
                  security
                </li>
                <li>
                  a{" "}
                  <strong>Continuous Integration and Deployment Review</strong>{" "}
                  reviewing CI/CD best practices and suggesting optimizations
                </li>
              </ul>
            </p>

            <h2 className="text-xl text-left leading-6 font-medium text-gray-900 border-b my-2 py-2">
              Are there any work samples confirming my expertise?
            </h2>
            <p className="text-base leading-6 text-gray-700 text-left mb-4">
              Of course there are! To this date, I have published a few
              StimulusReflex related resources:
              <ul className="list-disc list-outside mt-2 w-3/4 mx-auto">
                <li>
                  <a
                    className="text-magenta-900"
                    target="_blank"
                    href="https://www.stimulusreflexpatterns.com/"
                    rel="noopener noreferrer"
                  >
                    An intermediate course about common patterns
                  </a>
                </li>
                <li>
                  <a
                    className="text-magenta-900"
                    target="_blank"
                    href="https://www.stimulusreflexpatterns.com/patterns/"
                    rel="noopener noreferrer"
                  >
                    Interactive demos of some of those patterns
                  </a>
                </li>
                <li>
                  <a
                    className="text-magenta-900"
                    target="_blank"
                    href="https://blog.minthesize.com/tag/stimulus_reflex/"
                    rel="noopener noreferrer"
                  >
                    Blog posts about my experiences and learnings
                  </a>
                </li>
              </ul>
            </p>
            <h2 className="text-2xl md:text-3xl text-center leading-6 font-bold text-magenta-900 mt-12 mb-2 py-2">
              Have I raised your attention?
            </h2>
            <div className="flex justify-center">
              <LinkButton url="/contact" size="lg">
                Let&apos;s talk
              </LinkButton>
            </div>
          </Card>
          {/* <LogoCloud */}
          {/*   logos={[ */}
          {/*     { */}
          {/*       name: "weavs", */}
          {/*       url: "https://www.weavs.io", */}
          {/*     }, */}
          {/*     { */}
          {/*       name: "kreativfarm", */}
          {/*       url: "http://kreativ.farm/", */}
          {/*     }, */}
          {/*     { */}
          {/*       name: "inter-pool", */}
          {/*       url: "https://www.inter-pool.at", */}
          {/*     }, */}
          {/*   ]} */}
          {/* /> */}
        </>
      )}
    </Layout>
  );
};

export default StimulusReflexPage;
